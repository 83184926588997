.left-click-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 40%;
  cursor: pointer;
  z-index: 1;
}

.right-click-area {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 40%;
  cursor: pointer;
  z-index: 1;
}